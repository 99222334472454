import * as React from 'react';
import cls from 'classnames';

import { SectionBreak } from 'src/explore/elements/SectionBreak';
import { SvgUse } from 'src/shoppe/elements/SvgUse';
import { useRoutes } from 'src/explore/hooks/useRoutes';
import { useSettings } from 'src/explore/hooks/useSettings';
import { isNativeMobileApp } from 'src/explore/services/mobile';

import { SocialButton } from './elements/SocialButton';

import styles from './index.module.sass';

interface Props {
  ssoOrigin?: string;
}

export const SSOButtons: React.FC<Props> = ({ ssoOrigin }) => {
  const routes = useRoutes();
  const settings = useSettings();

  if ( settings?.mobileApp?.showSso !== 't' && isNativeMobileApp()) {
    return null;
  }

  return (
    <>
      <SectionBreak className={styles.sectionBreak} title="OR" />
      <div className={styles.authButtons}>
        {!isNativeMobileApp() && (
          <SocialButton
            className="spec__google-sso"
            href={routes.auth.google( ssoOrigin )}
            icon={<SvgUse ariaLabel="Google" className={cls( styles.icon )} name="social-google" />}
          >
            Continue with Google
          </SocialButton>
        )}
        <SocialButton
          className="spec__apple-sso"
          href={routes.auth.apple( ssoOrigin )}
          icon={
            <SvgUse
              ariaLabel="Apple"
              className={cls( styles.apple, styles.icon )}
              name="social-apple"
            />
          }
        >
          Continue with Apple
        </SocialButton>
        {!isNativeMobileApp() && (
          <SocialButton
            className="spec__facebook-sso"
            href={routes.auth.facebook( ssoOrigin )}
            icon={
              <SvgUse ariaLabel="Facebook" className={cls( styles.icon )} name="social-facebook" />
            }
          >
            Continue with Facebook
          </SocialButton>
        )}
      </div>
    </>
  );
};
