import React from 'react';
import cls from 'classnames';

import styles from './index.module.sass';

type Props = {
  className?: string;
  title: string;
};

export const SectionBreak: React.FC<Props> = ({ className, title }) => (
  <div className={cls( styles.container, className )}>
    <hr className={styles.rule} />
    <div className={styles.title}>{title}</div>
    <hr className={styles.rule} />
  </div>
);
