import * as React from 'react';
import cls from 'classnames';

import { Button } from '@goldbely/explore-component-library';
import styles from './index.module.sass';

interface Props {
  children?: React.ReactNode;
  className?: string;
  href: string;
  icon: React.ReactNode;
}

export const SocialButton: React.FC<Props> = ({ children, className, href, icon }) => {
  const handleClick = ( event: { currentTarget: any }) => {
    const target = event.currentTarget;
    setTimeout(() => {
      // If we disable synchronously than the form won't be submitted, queue it to be done immediately
      target.disabled = true;
    }, 0 );
  };

  return (
    <form action={href} method="post" className={cls( 'no-track', styles.container )}>
      <Button
        type="submit"
        variant="secondary"
        outline
        fullWidth
        className={cls( 'no-track', styles.button, className )}
        data-turbolinks="false"
        data-submit="false"
        onClick={handleClick}
      >
        <span className={styles.icon}>{icon}</span>
        <span className={styles.text}>{children}</span>
      </Button>
    </form>
  );
};
